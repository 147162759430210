import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreRouting } from './core.routing';
 import { PublicModule } from './../public/public.module';
import { SecureModule } from "./../secure/secure.module";
import { MiscellaneousModule } from "../miscellaneous/miscellaneous.module";
import { AuthModule } from "./auth/auth.module";
import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { UserProvider } from "../modules/user/user.provider";

export const CORE_PROVIDERS = [
  UserProvider
];
@NgModule({
	imports: [
		CommonModule,
		CoreRouting,
		AuthModule,
		SecureModule,
	],
	declarations: [],
	exports: [
		RouterModule,
	],
	providers: [
	],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...CORE_PROVIDERS],
    };
  }

}

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}
