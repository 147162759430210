import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { AuthGuard } from "../core/guards/auth.guard";
import { SecureHomePage } from './home/home.page';
import { SecureShellPage } from './shell/secure-shell.page';
import { AuthGuard } from "../core/auth/auth.guard";
import { ErrorComponent } from "../miscellaneous/error/error.component";
import { ForbiddenComponent } from "../miscellaneous/forbidden/forbidden.component";
import { environment } from "../environments/environment";
import { activityRequiredRoles, assetsRequiredRoles, buildingsRequiredRoles, usersRequiredRoles, dashboardsRequiredRoles, departmentsRequiredRoles, deviceRequiredRoles, floorsRequiredRoles, locationRequiredRoles, zonesRequiredRoles, siteRequiredRoles, alertDashboardRequiredRoles } from "../environments/module.permissions.config";


const routes: Routes = [
	{
		path: 'web',
		component: SecureShellPage,
    canActivate: [AuthGuard],
		children: [
			{ path: '', redirectTo: 'overview', pathMatch: 'full' },
			{ path: 'home', outlet: 'secure', component: SecureHomePage , data: { roles: [...environment.appMandetoryRole]  },  canActivate: [AuthGuard]},
      { path: 'notifications', outlet: 'secure', loadChildren: () => import("@Views/notifications/notifications.module").then(m => m.NotificationsModule), data: { roles: [...environment.appMandetoryRole] },  canActivate: [AuthGuard]},
      { path: "building", outlet: 'secure', loadChildren: () => import("@Modules/building/building.module").then(m => m.BuildingModule), data: { roles: [...environment.appMandetoryRole, ...buildingsRequiredRoles] },  canActivate: [AuthGuard]},
      { path: "floor", outlet: 'secure', loadChildren: () => import("@Modules/floor/floor.module").then(m => m.FloorModule), data: { roles: [...environment.appMandetoryRole, ...floorsRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "department", outlet: 'secure', loadChildren: () => import("@Modules/department/department.module").then(m => m.DepartmentModule), data: { roles: [...environment.appMandetoryRole, ...departmentsRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "zone", outlet: 'secure', loadChildren: () => import("@Modules/zone/zone.module").then(m => m.ZoneModule), data: { roles: [...environment.appMandetoryRole, ...zonesRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "location", outlet: 'secure', loadChildren: () => import("@Modules/location/location.module").then(m => m.LocationModule), data: { roles: [...environment.appMandetoryRole, ...locationRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "device", outlet: 'secure', loadChildren: () => import("@Modules/device/device.module").then(m => m.DeviceModule), data: { roles: [...environment.appMandetoryRole, ...deviceRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "entity", outlet: 'secure', loadChildren: () => import("@Modules/entity/entity.module").then(m => m.EntityModule), data: { roles: [...environment.appMandetoryRole, ...locationRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "thing", outlet: 'secure', loadChildren: () => import("@Modules/thing/thing.module").then(m => m.ThingModule), data: { roles: [...environment.appMandetoryRole, ...assetsRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "asset", outlet: 'secure', loadChildren: () => import("@Modules/asset/asset.module").then(m => m.AssetModule), data: { roles: [...environment.appMandetoryRole, ...assetsRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "realtime", outlet: 'secure', loadChildren: () => import("@Views/realtime/realtime.module").then(m => m.RealtimeModule), data: { roles: [...environment.appMandetoryRole, ...dashboardsRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "overview", outlet: 'secure', loadChildren: () => import("@Views/overview/overview.module").then(m => m.OverviewModule), data: { roles: [...environment.appMandetoryRole, ...dashboardsRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "activity", outlet: 'secure', loadChildren: () => import("@Views/activity/activity.module").then(m => m.ActivityModule), data: { roles: [...environment.appMandetoryRole, ...activityRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "dashboard", outlet: 'secure', loadChildren: () => import("@Views/dashboard/dashboard.module").then(m => m.DashboardModule), data: { roles: [...environment.appMandetoryRole, ...dashboardsRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "user", outlet: 'secure', loadChildren: () => import("@Modules/user/user.module").then(m => m.UserModule), data: { roles: [...environment.appMandetoryRole, ...usersRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "site", outlet: 'secure', loadChildren: () => import("@Modules/site/site.module").then(m => m.SiteModule), data: { roles: [...environment.appMandetoryRole, ...siteRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: "alert-dashboard", outlet: 'secure', loadChildren: () => import("@Views/alert-dashboard/alert-dashboard.module").then(m => m.AlertDashboardModule), data: { roles: [...environment.appMandetoryRole, ...alertDashboardRequiredRoles]  },  canActivate: [AuthGuard]},
      { path: 'error', outlet: 'secure', component: ErrorComponent },
			{ path: 'forbidden', outlet: 'secure',  component : ForbiddenComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SecureRouting { }
