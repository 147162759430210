import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any) {
    const snackBar = this.injector.get(MatSnackBar);

    if (error.message.includes('WebSocket connection')) {
      console.error('WebSocket Error:', error);
      snackBar.open('WebSocket connection failed.', 'Close', { duration: 5000 });
    } else {
      console.error('An error occurred:', error);
    }
  }
}
