import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "./../providers/config.provider";
import { UserModel } from "../models/user.model";
import { environment } from "../../environments/environment";
import { CURRENT_USER } from "../constants/session-strings.constant";
import { loggedInUser } from "../../core/helpers/utils";
import { Router } from "@angular/router";
import jwt_decode from 'jwt-decode';


@Injectable()
export class AuthenticationService {
  user: UserModel | null = null;
  baseUrl =environment.SethalaCore.Url
  constructor (private http: HttpClient,
    private router : Router,
    private configProvider: ConfigProvider) {
  }

    public currentUser(): UserModel | null {
        return loggedInUser();
    }

    public isAdminUser():boolean {
        var user =this. getProfile();
        //console.log(user)
        return user?.UserType=="Administrator";
    }
    public isSuperAdminUser():boolean {
      var user =this. getProfile();
      return user?.UserType=="Super Administrator";
    }
    public setPtofile(profile:any){
       sessionStorage.setItem("user_profile",JSON.stringify(profile));
    }
    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        sessionStorage.removeItem("user_profile");
        this.user = null;
        this.router.navigateByUrl('/web/(public:home)');
    }
    getProfile(): any {
        let user: any = {};
        try {
            user = JSON.parse(sessionStorage.getItem("user_profile")!);
        } catch (error) {

        }
        return user;
    }
}

