import { Component } from '@angular/core';
import {Router} from '@angular/router';
import { UserProvider } from "../modules/user/user.provider";
import { UserService } from "../modules/user/user.service";
import { MqttService } from "ngx-mqtt";

@Component({
	selector: 'app-host',
	template: `<router-outlet></router-outlet>`,
  providers:[UserProvider,UserService ]
})
export class AppHost {

	constructor(
	private router: Router,
  protected readonly profileService: UserProvider
	) {

	}

  async ngOnInit(): Promise<void> {

  await this.profileService
       .GetProfileInformation()
      //  .then((result) => {
      //   //console.log(result);
      //    if (result) {
      //      //console.log(result);
      //    }
      //  })
      //  .catch((err) => {
      //    console.log(err);
      // });
  }

}
