import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../service/auth.service';
import { AppCookieService } from "../cookies/app-cookie.service";
import { SITE_IDKEY, USER_DETAIL } from "../constants/variables";
import { AccessType } from "../enum/user-access.enum";
import { UserModel } from "../../shared/models/user.model";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
      private authenticationService: AuthenticationService,
      private readonly appCookieService: AppCookieService
    ) { }

    intercept(request: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUser();
        //console.log('http --->>', currentUser)
        //console.log(currentUser)
        if (currentUser && currentUser.Token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.Token}`
                }
            });
        }
        const userString = this.appCookieService.get(USER_DETAIL);
        let selectedSiteId = this.appCookieService.get(SITE_IDKEY);
        let isSuperAdmin = false;
        if (!userString) selectedSiteId = 'no-access';
        if (userString) {
          const user: UserModel = JSON.parse(userString);
          const access = <AccessType>user.UserType;
          isSuperAdmin = access == AccessType.SuperAdmin;
        }
        function setSiteId() {
            if (request.method == 'GET') {
              request = request.clone({
                setParams: {
                  SiteId: selectedSiteId,
                },
              });
            }
            if (request.method !== 'GET') {
              request = request.clone({
                body: {
                  ...request.body,
                  SiteId: selectedSiteId,
                },
              });
            }
        }
        if (selectedSiteId) {
          if (!request.url.includes('/site/')) {
            setSiteId();
          }
          if (request.url.includes('/site/')) {
            if (isSuperAdmin) {
            } else {
              setSiteId();
            }
          }
        }
        return next.handle(request);
    }
}
