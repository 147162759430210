import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from './../core/core.module';
import { AppHost } from './app.host';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { AuthModule } from "../core/auth/auth.module";
import { JwtInterceptor } from "../core/helpers/jwt.interceptor";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SharedModule } from "../shared/shared.module";
import { IMqttServiceOptions, MqttModule } from "ngx-mqtt";
import { GlobalErrorHandler } from "../shared/errors/error-handler";

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'sky1.sethala.com', // Replace with your MQTT broker hostname
  port: 8083,                    // Replace with your MQTT broker port
  path: '/mqtt',                   // Adjust the path as per your broker settings
  username: 'sethala-dev',
  password:'Q98Vb6Ctq2J328-22',
  protocol: 'wss',
};

@NgModule({
	declarations: [AppHost],
	imports: [
		BrowserModule,
		RouterModule,
		CoreModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
	  HttpClientModule,
		MatSnackBarModule,
    SharedModule,
    CoreModule.forRoot(),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS) //remeber to remove

	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
	],
	bootstrap: [AppHost],
})
export class AppModule {}
