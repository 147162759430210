import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { SiteViewModel } from "../../modules/site/site.model";



@Injectable({ providedIn : "root"})
export class SiteService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider
    ) {

  }


  List(filter:any={}): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/site/getAll",{ params: filter });
  }
  FilterSites(model: SiteViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/site/filterSites", model);
  }
  Create(model: SiteViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/site/create", model);
  }

  GetById(SiteId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/site/get/${SiteId}`);
  }

  GetDetails(SiteId): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + `/site/get-details/${SiteId}`);
  }

  validate(model: SiteViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/site/validate", model);
  }
  Update(model: SiteViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/site/update/"+model.SiteId, model);
  }
  Delete(model: SiteViewModel): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/site/delete", model);
  }

}

