import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscriber } from "rxjs";
import { tap, map, filter } from "rxjs/operators";
import { ConfigProvider } from "@Shared/providers/config.provider";
import { UserViewModel } from "./user.model";
import { IMqttMessage, MqttService } from "ngx-mqtt";


@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,
    private configProvider: ConfigProvider,
    private _mqttService: MqttService
    ) {

  }

  List(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/user/getAll");
  }
  GetProfileInformation(): any {
    return this.http.get(this.configProvider.GetSethalaIndoorApiUrl() + "/user/getProfile");
  }
  validate(model: any): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/user/validate", model);
  }

  Create(model: any): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/user/create", model);
  }

  Update(model: any): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/user/update", model);
  }

  Delete(model: any): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/user/delete", model);
  }

  sendTelegramNotification(model: any): any {
    return this.http.post(this.configProvider.GetSethalaIndoorApiUrl() + "/notification/sendTelegramNotification", model);
  }

  GetProfile(): Observable<IMqttMessage> {
    let message = this._mqttService.observe(`/profile/#`);
    //console.log(message)
    return message;
  }

}

