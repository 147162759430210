import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SiteViewModel } from "../../modules/site/site.model";
import { SiteService } from "../../shared/services/site.service";

@Injectable()
export class SiteProvider {
  constructor(
    private _siteService: SiteService,
  ) {
  }



  List() {
    return new Promise((result, exception) => {
      this._siteService.List().subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  GetById(siteId) {
    return new Promise((result, exception) => {
      this._siteService.GetById(siteId).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  GetDetails(siteId) {
    return new Promise((result, exception) => {
      this._siteService.GetDetails(siteId).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  Create(model: SiteViewModel) {
    return new Promise((result, exception) => {
      this._siteService.Create(model).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }


  Delete(model: SiteViewModel) {
    return new Promise((result, exception) => {
      this._siteService.Delete(model).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }

  Update(model: SiteViewModel) {
    return new Promise((result, exception) => {
      this._siteService.Update(model).subscribe(
        (response: any) => {
          result(response);
        },
        (error: any) => { exception(error); });
    });
  }
}
